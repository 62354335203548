import React from 'react';
import {useStore} from 'effector-react';
import { $groupBy, groupBy, GroupBy } from '../../models/groups';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';

export const Grouping = () => {
  const group = useStore($groupBy);
  
  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup row={true} value={group} onChange={(event) => groupBy(event.target.value as GroupBy)}>
          <FormControlLabel value="none" control={<Radio />} label="Не группировать" />
          <FormControlLabel value="on" control={<Radio />} label="On" />
          <FormControlLabel value="kun" control={<Radio />} label="Kun" />
          <FormControlLabel value="radical" control={<Radio />} label="Radical" />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
