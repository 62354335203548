import { send } from '../utils/anlytics';
import { fetchKanjis } from './kanjis';
import {
  setLessonFilter,
  setRadicalFilter,
  setKanjiFilter,
  setKunFilter,
  setOnFilter
} from './filters';
import { groupBy } from './groups';
import { setViewMode } from './viewMode';

fetchKanjis.watch(() => {
  send('shown');
});

setLessonFilter.watch(() => {
  send('filter', { type: 'lesson' })
});

setRadicalFilter.watch(() => {
  send('filter', { type: 'radical' })
});

setKanjiFilter.watch(() => {
  send('filter', { type: 'kanji' })
});

setKunFilter.watch(() => {
  send('filter', { type: 'kun' })
});

setOnFilter.watch(() => {
  send('filter', { type: 'on' })
});

groupBy.watch((data) => {
  send('group', { type: data })
});

setViewMode.watch((data) => {
  send('viewMode', { type: data })
});

