import React from 'react';
import cn from 'classnames';
import {useStore} from 'effector-react';
import {
  Button, Popover
} from '@material-ui/core';
import {$filtered} from "../../models/filteredKanji";
import {$radicalFilter, setRadicalFilter} from "../../models/filters";
import styles from './RadicalFilters.module.css';
import {flatten} from "lodash";

export const $kanjiRadicals = $filtered.map(infos => {
  const radicals = flatten(infos.map(i => i.radical))
  return Array.from(new Set(radicals));
})

export const RadicalFilter = () => {
  const kanjiRadicals = useStore($kanjiRadicals);
  const radicalFilter = useStore($radicalFilter);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div>
      <Button onClick={handleClick}>
        {radicalFilter.length ? `Radicals: ${radicalFilter.join(',')}` : 'Select Radical'}
      </Button>
      <Popover
        id="radical"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.container}>
          <div className={styles.radicals}>
            {
              kanjiRadicals.map(radical => (
                <button
                  key={radical}
                  className={cn(styles.radicalBtn, {[styles.radicalActiveBtn]: radicalFilter.includes(radical)})}
                  onClick={() => setRadicalFilter(radical)}
                >
                  {radical}
                </button>
              ))
            }
          </div>
          <Button className={styles.resetBtn} onClick={() => setRadicalFilter(null)}>Reset</Button>
        </div>
      </Popover>
    </div>
  );
}
