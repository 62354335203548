import React from 'react';
import {useStore} from 'effector-react';
import { $viewMode, setViewMode, ViewMode } from '../../models/viewMode';
import {
  ButtonGroup,
  Button
} from '@material-ui/core';

const modes: ViewMode[] = ['cards', 'compact']

export const ViewSelector = () => {
  const viewMode = useStore($viewMode);
  
  return (
    <ButtonGroup color="primary">
      {
        modes.map((mode) => (
          <Button
            key={mode}
            variant={mode === viewMode ? 'contained' : 'outlined'}
            onClick={() => setViewMode(mode)}
          >
            {mode}
          </Button>
        ))
      }
    </ButtonGroup>
  );
}
