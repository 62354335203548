import React from 'react';
import {useStore} from 'effector-react';
import { $sorted } from "../../models/sortedKanji";
import { $grouped } from "../../models/groupedKanji";

export const CompactList = () => {
  const items = useStore($grouped);
  const sorted = useStore($sorted);
  
  return (
    <div>
      {!sorted.length && <h2>Соли миста, ноу хэф(</h2>}
      {sorted.map(itemKey => (
        <div key={itemKey}>
          <p>
            {
              itemKey
              ? <b>{itemKey}: </b>
              : null
            }
            {items[itemKey].map((item) => item.kanji).join(', ')}
          </p>
        </div>
      ))}
    
    </div>
  );
}
