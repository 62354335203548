import React from 'react';
import { useStore } from 'effector-react';
import { Filters } from '../Filters';
import { CompactList } from '../CompactList';
import { CardsList } from '../CardsList';
import { Grouping } from '../Grouping';
import { ViewSelector } from '../ViewSelector';
import { fetchKanjis, $kanjiFetchStatus } from '../../models/kanjis';
import { fetchWords } from '../../models/words';
import { $viewMode } from '../../models/viewMode';
import { CircularProgress } from '@material-ui/core';

function App() {
  React.useEffect(() => {
    fetchKanjis();
    fetchWords();
  }, []);
  
  const status = useStore($kanjiFetchStatus);
  const viewMode = useStore($viewMode);
  
  return (
    <div>
      
      {status === 'loading' && <CircularProgress />}
      {status === 'success' &&
      <>
        <Filters />
        <Grouping />
        <br />
        <ViewSelector />
        {viewMode === 'cards' && <CardsList />}
        {viewMode === 'compact' && <CompactList />}
      </>
      }
    </div>
  );
}

export default App;
