import React from 'react';
import {useStore} from 'effector-react';
import { $sorted } from "../../models/sortedKanji";
import { $grouped } from "../../models/groupedKanji";
import { Card, CardContent } from '@material-ui/core';

export const CardsList = () => {
  const items = useStore($grouped);
  const sorted = useStore($sorted);
  
  return (
    <div>
      {!sorted.length && <h2>Соли миста, ноу хэф(</h2>}
      {sorted.map(itemKey => (
        <div key={itemKey}>
          <h3>{itemKey} {sorted.length > 1 && `(${items[itemKey].length})`}</h3>
          {items[itemKey].map((item, i) => (
          <Card key={`${itemKey}${item.kanji}${i}`} style={{ marginTop: 10}}>
            <CardContent>
              <h2>{item.kanji}</h2>
              <div><b>On:</b> {item.on.join(', ')}</div>
              <div><b>Kun:</b> {item.kun.join(', ')}</div>
              <div><b>Radical:</b> {item.radical.join(', ')}</div>
              <div><b>Words:</b> {item.words.join(', ')}</div>
              <div><b>Lesson:</b> {item.lesson}</div>
            </CardContent>
          </Card>
          ))}
        </div>
      ))}
    
    </div>
  );
}
