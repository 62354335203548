import {createStore, createEffect, restore} from 'effector';
import { KanjiInfo } from '../types';
import { GoogleSpreadsheet } from 'google-spreadsheet';

const TABLE_LENGTH = 1500;

export const fetchWords = createEffect(async () => {
  const doc = new GoogleSpreadsheet('1yci7ZvHT2R5OtGnxVDlMNZB3M3cF3pYNPY9XXiPIPXE');
  doc.useApiKey('AIzaSyDotoiCb3URuhu-JpahcbBRPK9iQ051rgw');
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[1];
  await sheet.loadCells(`A1:A${TABLE_LENGTH}`);
  const data: string[] = []
  
  for(let i = 1; i<=TABLE_LENGTH; i++) {
    try {
      if (sheet.getCell(i, 0).value) {
        data.push(sheet.getCell(i, 0).value as string)
      }
    } catch (e) {}
    
  }
  
  console.log(`words: ${data.length}`);
  
  return data;
})



export const $words = restore<string[]>(fetchWords,[])
export const $wordsFetchStatus = createStore<'idle' | 'loading' | 'success' | 'error'>('idle')
  .on(fetchWords, () => 'loading')
  .on(fetchWords.done, () => 'success')
  .on(fetchWords.fail, () => 'error')




