import React from 'react';
import {useStore} from 'effector-react';
import {
  MenuItem, TextField
} from '@material-ui/core';
import {$lessonFilter, setLessonFilter} from "../../models/filters";
import {$kanjiLessons} from "../../models/kanjis";

export const LessonFilter = () => {
  const lessonFilter = useStore($lessonFilter);
  const kanjiLessons = useStore($kanjiLessons);
  
  return (
    <TextField
      select
      name="Lessons"
      id="Lessons"
      variant="outlined"
      label="Lessons"
      style={{ margin: 10, width: 120 }}
      SelectProps={{
        multiple: true,
        value: lessonFilter,
        onChange: (e) => {
          const values: number[] = (e as any).target.value;
          const clicked = Number(values[values.length - 1]);
          
          if (clicked) {
            setLessonFilter(clicked);
          } else {
            setLessonFilter(null);
          }
        }
      }}
    >
      <MenuItem key="empty" value="">Все уроки</MenuItem>
      {kanjiLessons.map(lesson => (<MenuItem key={lesson} value={`${lesson}`}>{lesson}</MenuItem>))}
    </TextField>
  )
}
