import {combine} from "effector";
import {$filtered} from "./filteredKanji";
import {$groupBy} from "./groups";
import {groupBy} from "../utils/group";

export const $grouped = combine($filtered, $groupBy, (filtered, grouped) => {
  // Todo on has array of values. One kanjiInfo can be in different groups.
  switch (grouped) {
    case 'none': return { "" : filtered }
    case 'on': return groupBy<typeof filtered[0]>(filtered, (item) => item.on)
    case 'kun': return groupBy<typeof filtered[0]>(filtered, (item) => item.kun)
    case 'radical': return groupBy<typeof filtered[0]>(filtered, (item) => item.radical)
    default: return { "" : filtered }
  }
})
