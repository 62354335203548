import hiragana from './hiragana.json';
import katakana from './katakana.json';

hiragana.sort((a, b) => b.roumaji.length - a.roumaji.length);
katakana.sort((a, b) => b.roumaji.length - a.roumaji.length);

interface LibItem {
  kana: string,
  roumaji: string,
  type: string
}

type Lib = LibItem[]

const convert = (str: string, obj: Lib) => {
  let value = str;
  for (let k of obj) {
    const reg = new RegExp(`${k.roumaji}`, 'gm')
    value = value.replace(reg, k.kana);
    value = value.replace(/-/gm, 'ー');
  }
  
  return value;
}

export const toKatakana = (str: string) => {
  return convert(str, katakana)
}


export const toHiragana = (str: string) => {
  return convert(str, hiragana)
}


