import { restore, combine, createEvent, createStore } from 'effector';

export const setKanjiFilter = createEvent<string>()
export const setLessonFilter = createEvent<number | null>()

export const setOnFilter = createEvent<string>()
export const setKunFilter = createEvent<string>()
export const setRadicalFilter = createEvent<string | null>()

export const $kanjiFilter = restore<string>(setKanjiFilter, '')

export const $onFilter = restore<string>(setOnFilter, '')
export const $kunFilter = restore<string>(setKunFilter, '');

export const $radicalFilter = createStore<string[]>([])
  .on(setRadicalFilter, (state, payload) => {
    if (!payload) return [];
    if (state.includes(payload)) return state.filter(i => i !== payload)
    else return [...state, payload]
  })

export const $lessonFilter = createStore<number[]>([])
  .on(setLessonFilter, (state, payload) => {
    if (!payload) return [];
    if (state.includes(payload)) return state.filter(i => i !== payload)
    else return [...state, payload]
  })

export const $filtersValue = combine(
  $kanjiFilter,
  $onFilter,
  $kunFilter,
  $radicalFilter,
  $lessonFilter,
  (kanjiFilter,
   onFilter,
   kunFilter,
   radicalFilter,
   lessonFilter
  ) => ({
    kanjiFilter,
    onFilter,
    kunFilter,
    radicalFilter,
    lessonFilter
  })
)
