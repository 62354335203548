import amplitude from 'amplitude-js';

const analytics = amplitude.getInstance()

analytics.init('ee291e15f0e284f199b02bd2ec217226');

export function send(event: string, payload?: any) {
  if (process.env.NODE_ENV === 'development') {
    console.log(event, payload);
    return;
  }
  analytics.logEvent(event, {
    project: 'kanji_bkb',
    host: window.location.host,
    payload
  });
}
