import React  from 'react';
import {useStore} from 'effector-react';
import {
  $kanjiFilter,
  $onFilter,
  $kunFilter,
  setKanjiFilter,
  setOnFilter,
  setKunFilter,
} from "../../models/filters";
import {
  TextField
} from '@material-ui/core';
import { RadicalFilter } from '../RadicalFilter';
import { LessonFilter } from '../LessonFilter';
import { toKatakana, toHiragana } from '../../utils/romajiConverter';

const style = {
  margin: 10,
  minWidth: 100
}

export const Filters = () => {
  const kanjiFilter = useStore($kanjiFilter);
  const onFilter = useStore($onFilter);
  const kunFilter = useStore($kunFilter);
  
  return (
    <div>
      <div>
        <TextField style={style} label="Kanji" value={kanjiFilter} variant="outlined" onChange={(e) => {
          setKanjiFilter(e.target.value)
        }}/>
      </div>
      <div>
        <TextField helperText={<span>{toKatakana(onFilter) || 'Значение будет переведено в катакану'}</span>} style={style} label="On" value={onFilter} variant="outlined" onChange={(e) => {
          setOnFilter(e.target.value);
        }}/>
        <TextField helperText={<span>{toHiragana(kunFilter) || 'Значение будет переведено в хирагану'}</span>} style={style} label="Kun" value={kunFilter} variant="outlined" onChange={(e) => {
          setKunFilter(e.target.value);
        }}/>
        <LessonFilter />
      </div>
      <RadicalFilter />
    </div>
  );
}
