
export const groupBy = <Item>(arr: Item[], iterate: (i: Item) => Array<string>): {[key: string]: Item[]} => {
  const result: {[key: string]: Item[]} = {};
  
  for(let item of arr) {
    const array = iterate(item);
  
    for(let i of array) {
      if (result[i]) {
        result[i].push(item)
      } else {
        result[i] = [item]
      }
    }
  }
  
  return result
}
