import {combine} from "effector";
import {$kanjiInfos} from "./kanjis";
import {$words} from "./words";

export const $kanjiInfosWithWords = combine(
  $kanjiInfos,
  $words,
  (kanjiInfos, words) => kanjiInfos.map(kanjiInfo => ({
    ...kanjiInfo,
    words: words.filter(word => word.includes(kanjiInfo.kanji))
  }))
)
