import {combine} from "effector";
import {$kanjiInfosWithWords} from "./kanjiWithWords";
import {$filtersValue} from "./filters";
import {toHiragana, toKatakana} from "../utils/romajiConverter";

export const $filtered = combine($kanjiInfosWithWords, $filtersValue, (kanjiInfos, filters) => {
  let result = [...kanjiInfos];
  
  if (filters.kanjiFilter) {
    result = result.filter((kanjiInfo) => kanjiInfo.kanji === filters.kanjiFilter)
  }
  
  if (filters.onFilter) {
    result = result.filter((kanjiInfo) => kanjiInfo.on.some(i => i.includes(toKatakana(filters.onFilter))))
  }
  
  if (filters.kunFilter) {
    result = result.filter((kanjiInfo) => kanjiInfo.kun.some(i => i.includes(toHiragana(filters.kunFilter))))
  }
  
  if (filters.radicalFilter.length) {
    result = result.filter((kanjiInfo) => filters.radicalFilter?.every(i => kanjiInfo.radical.includes(i)))  }
  
  if (filters.lessonFilter.length) {
    result = result.filter((kanjiInfo) => filters.lessonFilter.includes(kanjiInfo.lesson))
  }
  
  return result
})
