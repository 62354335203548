import {createStore, createEffect, restore} from 'effector';
import { KanjiInfo } from '../types';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { flatten } from 'lodash';


const TABLE_LENGTH = 500;

export const fetchKanjis = createEffect(async () => {
  const doc = new GoogleSpreadsheet('1yci7ZvHT2R5OtGnxVDlMNZB3M3cF3pYNPY9XXiPIPXE');
  doc.useApiKey('AIzaSyDotoiCb3URuhu-JpahcbBRPK9iQ051rgw');
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[0];
  await sheet.loadCells(`A1:F${TABLE_LENGTH}`);
  const data: KanjiInfo[] = []
  
  for(let i = 1; i<=TABLE_LENGTH; i++) {
    if (!sheet.getCell(i, 0).value) {
      break;
    }
    try {
      const info = {
        kanji: (sheet.getCell(i, 0).value as string ?? ''),
        on: (sheet.getCell(i, 1).value as string ?? '').split(/[,、]/).map(i => i.trim()),
        kun: (sheet.getCell(i, 2).value as string ?? '').split(/[,、]/).map(i => i.trim()),
        radical: (sheet.getCell(i, 3).value as string ?? '').split(/[,、]/).map(i => i.trim()),
        lesson: Number(sheet.getCell(i, 4).value as string ?? ''),
      };
      data.push(info)
    } catch(e) {
      console.log(e)
    }
  }
  return data;
})



export const $kanjiInfos = restore<KanjiInfo[]>(fetchKanjis,[])
export const $kanjiFetchStatus = createStore<'idle' | 'loading' | 'success' | 'error'>('idle')
  .on(fetchKanjis, () => 'loading')
  .on(fetchKanjis.done, () => 'success')
  .on(fetchKanjis.fail, () => 'error')

export const $kanjiLessons = $kanjiInfos.map(infos =>
  Array.from(new Set(infos.map(i => i.lesson)))
)


